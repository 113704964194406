body {
  color: #042134;
  font-family: 'Euclid Circular A', 'Poppins';
  /* padding-top: 72px; */
}

* {
  box-sizing: border-box;
}

button,
a {
  cursor: pointer;
  transition: 0.3s;
  color: inherit;
  font-weight: 600;
}

nav {
  /* position: fixed; */
  /* translate: 0 -72px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* gap: 20px; */
  /* padding: 0 20px; */
  /* width: 100%; */
  /* height: 72px; */
  /* box-shadow: 0 10px 20px rgb(0 0 0 / 10%); */
  /* background: #042134; */
  transition: 0.3s;
}

nav.visible {
  top: 0;
  translate: 0;
}

nav > img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 16px;
}

.nav-items > a {
  text-decoration: none;
  color: rgb(255 255 255 / 96%);
  height: 72px;
  display: grid;
  place-items: center;
}

.nav-items > a:hover {
  color: rgb(255 255 255 / 96%);
}

h2 {
  font-size: 20px;
  margin: 0 0 4px;
  cursor: default;
}

section {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 100px 60px;
}

section.shaded {
  background: #f9f9ff;
}

section > img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: contain;
  padding: 50px;
  background: #f9f9ff;
}

section.shaded > img {
  padding: 20px;
}

section > p {
  line-height: 1.7;
}

.connect-btn {
  background: linear-gradient(90.71deg, #73e0a9 0%, #5b68df 100%);

}
.connect-btn:hover{

  background: linear-gradient(90.71deg, #5b68df 0%, #73e0a9 100%);

}
.icon:hover{
  color: #042134;
}
